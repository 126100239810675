<template>
  <div class="wrap">
    <div class="card_wrap">
      <div class="card">
        <div class="label">
          <div class="th">{{docDetail.name || docDetail.doctorName}}</div>
          <div class="td">图文咨询</div>
          <div class="remark"><span class="money_wrap">¥<span class="money">{{docDetail.price || devPrice}}</span>次</span></div>
        </div>
        <div class="label">
          <div class="th">机构</div>
          <div class="td" v-if="docData.doctorHospitalName">{{docData.doctorHospitalName}}</div>
          <div class="td" v-else>{{docDetail.hospital_name || docDetail.hospital || docDetail.doctorHospitalName}}</div>
          <div class="remark"></div>
        </div>
        <div class="label">
          <div class="th">职称</div>
          <div class="td" v-if="docData.doctorProfessionalTitle">{{docData.doctorProfessionalTitle}}</div>
          <div class="td" v-else>{{docDetail.title || docDetail.doctorProfessionalTitle}}</div>
          <div class="remark"></div>
        </div>
        <!-- <div class="label">
          <div class="th">机构</div>
          <div class="td">{{docDetail.hospital_name || docDetail.hospital || docDetail.doctorHospitalName}}</div>
          <div class="remark"></div>
        </div>
        <div class="label">
          <div class="th">职称</div>
          <div class="td">{{docDetail.title || docDetail.doctorProfessionalTitle}}</div>
          <div class="remark"></div>
        </div> -->
      </div>
      <div class="card">
        <div class="label">
          <div class="th grey">对话回合</div>
          <div class="td">有效时长内咨询回复次数</div>
          <div class="remark">50次</div>
        </div>
        <div class="label">
          <div class="th grey">对话时长</div>
          <div class="td">有效时长</div>
          <div class="remark">48小时</div>
        </div>
        <div class="line"></div>
        <div class="label">
          <div class="th bold">共需支付</div>
          <div class="td"></div>
          <div class="remark"><span class="money_wrap">¥<span class="money">{{docDetail.price || devPrice}}</span></span></div>
        </div>
      </div>
    </div>
    <div class="toPay" @click="toPay">去支付</div>
  </div>
</template>
<script>
import { isWeiXin } from '@/utils/util';
import { Toast } from 'vant';
let self = null;
export default {
  data() {
    return {
      docDetail: this.$store.state.docDetail,
      devPrice: this.$store.state.devPrice,
      docData: {},
      timer: null
    }
  },
  created() {
    self = this;
    // console.log('this.docDetail', this.$store.state.docDetail);
    // this.docDet  ail.price = (this.docDetail.price * (1 + this.$store.state.floatRate)).toFixed(2);

  },
  mounted() {
    if (this.$route.query.orderId) {
      this.$nextTick(() => {

        this.getOrderInfo(this.$route.query.orderId)
      })
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    // 获取第三方创建订单参数
    getCreatedOrderParams(orderId) {
      let docDetail = self.$store.state.docDetail;
      let message = self.$store.state.message;
      let img = self.$store.state.arrImg;
      let userData = JSON.parse(localStorage.getItem('userData')) || '';
      let relation = self.$store.state.relation;
      let content = [
        { type: 'text', text: message },
        { type: 'patient_meta', age: relation.age, sex: relation.sex }
      ]
      if (img) {
        content.push({ type: 'image', file: img.join(',') })
      }
      let params = {
        doctor_ids: docDetail.id,
        content: JSON.stringify(content),
        partner_order_id: orderId,
        // pay_type: 'qc_hospital_common', //二甲医院医生
        price: (docDetail.price / (1 + this.$store.state.floatRate)) * 100,
      }
      // let params = {
      //   doctor_ids: docDetail.id,
      //   content: JSON.stringify(content),
      //   partner_order_id: orderId,
      //   price: (docDetail.price / (1 + this.$store.state.floatRate)) * 100,
      // }
      return params;
    },
    createDevOrder() {
      let docDetail = this.$store.state.docDetail;
      let message = this.$store.state.message;
      let img = this.$store.state.arrImg;
      // console.log('医生详情=====》', docDetail);
      let userData = JSON.parse(localStorage.getItem('userData')) || ''
      let doctorInfo = {};
      if (this.$store.state.docType == 2 || docDetail.clinic_name) {
        doctorInfo = {
          doctorId: docDetail.id,
          doctorName: docDetail.name,
          doctorClinic: docDetail.clinic_name,
          doctorImage: docDetail.image,
          doctorConsultPrice: docDetail.price,
          doctorHospitalName: docDetail.hospital_name || docDetail.hospital,
          doctorProfessionalTitle: docDetail.title
        }
      } else {
        doctorInfo = {
          doctorId: docDetail.doctorId,
          doctorName: docDetail.doctorName,
          doctorClinic: docDetail.doctorClinic,
          doctorImage: docDetail.doctorHeadImg,
          doctorConsultPrice: this.devPrice,
          doctorHospitalName: docDetail.doctorHospitalName,
          doctorProfessionalTitle: docDetail.doctorProfessionalTitle
        }
      }
      // console.log('医生信息=====》', doctorInfo);
      let params = {
        memberId: userData.memberId,
        consultType: 1,
        templateType: 2,
        doctorBelong: 2,
        orderPrice: docDetail.price,
        doctorInfo: doctorInfo,
        interrogationId: this.$store.state.interrogationId,
        questionContent: message,
        questionImage: img && img.join(',')
      }
      if (this.$store.state.docType == 1) { // 2=春雨
        params.doctorBelong = 1;
        params.orderPrice = this.devPrice;
      }
      // let self = this;
      this.$http('post', '/server/order/addOrder', params, 1).then(res => {
        // console.log(res, this.$store.state.docType);
        if (res.data.code == 200) {
          if (isWeiXin()) {
            wx.miniProgram.getEnv(function (resData) {//获取当前环境
              // console.log(resData);
              // console.log('getCreatedOrderParams================>', self.getCreatedOrderParams(res.data.data.orderId));
              if(resData.miniprogram) { // true 在微信小程序中
                // console.log('小程序支付');
                let url = '';
                if (self.$store.state.docType == '1') {
                  let devDoctorParams = {
                    problemId: res.data.data.orderId,
                    doctorId: self.docDetail.doctorId,
                    templateType: 2,
                    interrogationId: self.$store.state.interrogationId,
                    interrogationName: ''
                  }
// _this.$router.replace({ path: '/dev_chating', query: { problemId: orderId, doctorId: _this.docDetail.doctorId, templateType: 2, interrogationId: _this.$store.state.interrogationId, interrogationName: '' } })
                  url = `/pages/topay?price=${params.orderPrice}&orderId=${res.data.data.orderId}&isDevDirectional=1&devDoctorParams=${JSON.stringify(devDoctorParams)}`;
                } else {
                  url = `/pages/topay?price=${params.orderPrice}&orderId=${res.data.data.orderId}&thirdOrder=${JSON.stringify(self.getCreatedOrderParams(res.data.data.orderId))}&pageType=directional`;
                }
                wx.miniProgram.navigateTo({
                  // url:`/pages/topay?price=${params.orderPrice}&orderId=${res.data.data.orderId}`,//跳转回小程序的页面
                  url: url, //跳转回小程序的页面
                  success: function(){
                    // console.log('success');
                    // self.timer = setInterval(() => {
                    //   self.selectByOrderId(res.data.data.orderId);
                    // }, 2000)
                  },
                  fail: function(){
                    // console.log('fail');
                  }
                });
              }else{ // false 在微信公众号里
                // console.log('微信浏览器支付');
                self.pay(res.data.data.orderId);
              }
            });
          } else {
            let openid = localStorage.getItem('openid') || '';
            if (openid) {  //公众号支付
              this.pay(res.data.data.orderId);
            } else {
              // this.pay();
              this.wxPay(res.data.data.orderId);
            }
          }
          // this.thirdOrder(res.data.data.orderId);
        }
      })
    },
    // 查询订单状态
    selectByOrderId(orderId) {
      let params = {
        orderId: orderId
      }
      let docDetail = this.$store.state.docDetail;
      this.$http('get', '/server/order/selectByOrderId', params, 1).then(res => {
        if (res.data.code == 200) {
          // console.log('订单状态', res);
          if (res.data.data.orderStatus >= 1) {
            clearInterval(this.timer);
            if (this.$store.state.docType == 2) {

              this.thirdOrder(orderId);
            } else {
              this.$router.replace({ path: '/consult' })
            }
          } else {
            // clearInterval(this.timer);
            // // console.log('去列表页面===========================');
            this.$store.state.message = '';
            this.$store.state.arrImg = null;
            this.$router.replace({ path: '/consult' })
          }
        }
      })
    },
    // 微信支付
    wxPay(orderId) {
      let data = {
        subject: '充值',
        body: '在线医生',
        price: this.$store.state.docType == 2 ? this.docDetail.price : this.devPrice,//0.01,
        orderNumber: orderId
      }
      this.$http('post', '/server/pay/getH5QrPay', data, 1).then(res => {
        // console.log('支付', res)
        if (res.data.code == 200) {
          // console.log(res.data.data.wapUrl)
          let a = document.createElement("a");
          a.href = res.data.data.wapUrl;
          a.click();
          this.timer = setInterval(() => {
            this.selectByOrderId(orderId);
          }, 2000)
        }
      })
    },
    // 公众号支付
    pay(orderId) {
      let _this = this;
      let openid = localStorage.getItem('openid') || '';
      let data = {
        subject: '充值',
        body: '在线医生',
        price: _this.$store.state.docType == 2 ? _this.docDetail.price : _this.devPrice,//0.01, _this.docDetail.price
        orderNumber: orderId,
        openid: openid,
        programType: 2
      }
      _this.$http('post', '/server/pay/getJsApiQrPay', data, 1).then(res => {
        // console.log('公众号', res)
        let gzhData = res.data.data;
        // console.log('参数', gzhData.appId)
        WeixinJSBridge.invoke(
          'getBrandWCPayRequest', {
            "appId": gzhData.appId,     //公众号名称，由商户传入
            "timeStamp": gzhData.timeStamp,         //时间戳，自1970年以来的秒数
            "nonceStr": gzhData.nonceStr, //随机串
            "package": `prepay_id=${gzhData.tradeNo}`,
            "signType": "MD5",         //微信签名方式：
            "paySign": gzhData.paySign //微信签名
          },
          function (res) {
            // console.log(res);
            if (res.err_msg == "get_brand_wcpay_request:ok") {
              // 使用以上方式判断前端返回,微信团队郑重提示：
              if (_this.$store.state.docType == 2) {

                _this.thirdOrder(orderId);
              } else {
                _this.$store.state.message = '';
                _this.$store.state.arrImg = null;
                _this.$router.replace({ path: '/dev_chating', query: { problemId: orderId, doctorId: _this.docDetail.doctorId, templateType: 2, interrogationId: _this.$store.state.interrogationId, interrogationName: '' } })
              }
              //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            } else {
              Toast('支付失败');
              _this.$store.state.message = '';
              _this.$store.state.arrImg = null;
              _this.$router.replace({ path: '/consult' })
            }
          });
      })
    },
    thirdOrder(orderId) {
      let docDetail = this.$store.state.docDetail;
      let message = this.$store.state.message;
      let img = this.$store.state.arrImg;
      let userData = JSON.parse(localStorage.getItem('userData')) || '';
      let relation = this.$store.state.relation;
      let content = [
        { type: 'text', text: message },
        { type: 'patient_meta', age: relation.age, sex: relation.sex }
      ]
      if (img) {
        content.push({ type: 'image', file: img.join(',') })
      }
      let params = {
        doctor_ids: docDetail.id,
        content: JSON.stringify(content),
        partner_order_id: orderId,
        price: (docDetail.price / (1 + this.$store.state.floatRate)) * 100,
      }
      this.$http('post', `/server/thirdAddOrder/addOrientedOrder/${orderId}`, {}).then(res => {
        // this.$http('post', '/cooperation/server/problem/create_oriented_problem/', params).then(res => {
        // console.log('春雨订单', res);
        if (res.data.error == 0) {
          let query = {
            // problemId: res.data.problems[0].problem_id,
            // doctorId: res.data.problems[0].doctor_id,
            problemId: res.data.problem_id,
            doctorId: res.data.doctor_id,
            number: 50
          }
          this.$store.state.message = '';
          this.$store.state.arrImg = null;
          // this.bindOrder(orderId, res.data.problem_id);
          // this.bindOrder(orderId, res.data.problems[0].problem_id);
          this.$router.replace({ path: '/chating', query: query })
        }
      })
    },
    // 订单号与问题单号绑定接口
    bindOrder(orderId, problemId) {
      let params = {
        orderId: orderId,
        problemId: problemId
      }
      this.$http('post', '/server/problemOrder/orderAndProBind', params, 1).then(res => {
        // console.log(res)
      })
    },
    // 查询订单信息
    getOrderInfo(orderId) {
      let params = {
        orderId: orderId
      }
      this.$http('get', '/server/order/selectByOrderId', params, 1).then(res => {
        if (res.data.code == 200) {
          // console.log('查询订单信息', res);
          this.devPrice = res.data.data.orderPrice;
          this.docDetail.price = res.data.data.orderPrice;
          this.$store.state.message = res.data.data.questionContent;
          this.docData = res.data.data.doctorInfo || {};
          if (res.data.data.questionImage) {
            this.$store.state.arrImg = res.data.data.questionImage.split(',');
          } else {
            this.$store.state.arrImg = null;
          }
          this.$store.state.relation = { age: res.data.data.age, sex: res.data.data.sex == 1 ? '男' : '女' }


        }
      })
    },
    toPay() {
      // let self = this;
      if (this.$route.query.orderId) {
        if (isWeiXin()) {
          wx.miniProgram.getEnv(function (resData) {//获取当前环境
            if(resData.miniprogram){ // true 在微信小程序中
              // console.log('小程序支付');
              // console.log('getCreatedOrderParams================>', self.getCreatedOrderParams(self.$route.query.orderId));
              let url = `/pages/topay?price=${self.docDetail.price}&orderId=${self.$route.query.orderId}&thirdOrder=${JSON.stringify(self.getCreatedOrderParams(self.$route.query.orderId))}&pageType=fast`;
              wx.miniProgram.navigateTo({
                // url:`/pages/topay?price=${self.docDetail.price}&orderId=${self.$route.query.orderId}`,//跳转回小程序的页面
                url: url,//跳转回小程序的页面
                success: function(){
                    // console.log('success===============');
                    // self.timer = setInterval(() => {
                    //   // console.log('=================去列表页面=======================');
                    //   self.selectByOrderId(self.$route.query.orderId);
                    // }, 2000);
                },
                fail: function(){
                  // console.log('fail');
                }
              });
            }else{ // false 在微信公众号里
              // console.log('微信浏览器支付');
              self.pay(self.$route.query.orderId);
            }
          });
        } else {
          let openid = localStorage.getItem('openid') || '';
          if (openid) {  //公众号支付
            this.pay(this.$route.query.orderId);
          } else {
            this.wxPay(this.$route.query.orderId);
          }
        }
      } else {
        this.createDevOrder();
      }
    },
  }
}
</script>

<style lang="less" scoped>
.wrap {
  padding: 0.2rem 0;
  min-height: calc(100% - 0.4rem);
  background: #f8f8f8;
}
.card_wrap {
  min-height: 6rem;
}
.card {
  padding: 0.4rem 0.24rem 0.01rem;
  margin-bottom: 0.16rem;
  background: #fff;
  .label {
    display: flex;
    display: -webkit-flex;
    margin-bottom: 0.35rem;
    .th {
      width: 1.36rem;
      font-size: 0.26rem;
      color: #333333;
      font-weight: bold;
    }
    .td {
      flex: 1;
      -webkit-flex: 1;
      font-size: 0.28rem;
      color: #666666;
    }
    .remark {
      .money_wrap {
        font-size: 0.24rem;
        color: #ffc025;
        .money {
          font-size: 0.3rem;
          font-weight: bold;
        }
      }
    }
    .grey {
      font-weight: 500;
      font-size: 0.28rem;
      color: #333333;
    }
    .bold {
      font-size: 0.3rem;
    }
  }
  .line {
    margin: 0 0.24rem 0.28rem;
    height: 2px;
    background: #f2f2f2;
  }
}
.toPay {
  margin: 0 0.4rem;
  height: 0.72rem;
  line-height: 0.72rem;
  font-size: 0.32rem;
  text-align: center;
  border-radius: 0.08rem;
  background: #42cec8;
  color: #fff;
}
</style>
